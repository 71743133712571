import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client"
import fetch from "isomorphic-fetch"

const httpLink = createHttpLink({
  uri: "https://pomareslawgroup.smr-sandbox.com/my_wp_admin/graphql",
  fetch,
})

const link = ApolloLink.from([httpLink])

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
})
